import { styled } from '../../theme/stitches.config';
export const VStack = styled('div', {
  $$customSpacing: '',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  variants: {
    widthAuto: {
      true: {
        width: 'auto'
      }
    },
    relative: {
      true: {
        position: 'relative'
      }
    },
    alignItems: {
      center: {
        alignItems: 'center'
      },
      flexStart: {
        alignItems: 'flex-start'
      },
      flexEnd: {
        alignItems: 'flex-end'
      }
    },
    justifyContent: {
      center: {
        justifyContent: 'center'
      },
      flexStart: {
        justifyContent: 'flex-start'
      },
      spaceBetween: {
        justifyContent: 'space-between'
      },
      evenly: {
        justifyContent: 'space-evenly'
      },
      around: {
        justifyContent: 'space-around'
      }
    },
    flex: {
      1: {
        flex: '1'
      },
      0: {
        flex: '0'
      }
    },
    p: {
      1: {
        p: '$1'
      },
      2: {
        p: '$2'
      },
      3: {
        p: '$3'
      },
      4: {
        p: '$4'
      },
      5: {
        p: '$5'
      },
      6: {
        p: '$6'
      },
      7: {
        p: '$7'
      },
      8: {
        p: '$8'
      },
      9: {
        p: '$9'
      }
    },
    spacing: {
      '0': {},
      '1': {
        '& > :not(:first-child)': {
          mt: '$1'
        }
      },
      '2': {
        '& > :not(:first-child)': {
          mt: '$2'
        }
      },
      '3': {
        '& > :not(:first-child)': {
          mt: '$3'
        }
      },
      '4': {
        '& > :not(:first-child)': {
          mt: '$4'
        }
      },
      '5': {
        '& > :not(:first-child)': {
          mt: '$5'
        }
      },
      '6': {
        '& > :not(:first-child)': {
          mt: '$6'
        }
      },
      '7': {
        '& > :not(:first-child)': {
          mt: '$7'
        }
      },
      '8': {
        '& > :not(:first-child)': {
          mt: '$8'
        }
      },
      '9': {
        '& > :not(:first-child)': {
          mt: '$9'
        }
      },
      '10': {
        '& > :not(:first-child)': {
          mt: '$10'
        }
      },
      custom: {
        '& > :not(:first-child)': {
          mt: '$$customSpacing'
        }
      }
    }
  },
  defaultVariants: {
    spacing: '3',
    alignItems: 'center'
  }
});