import Axios from 'axios'
import { setCookie } from 'cookies-next'
import { Channel, ChannelAuthorizationCallback } from 'pusher-js'

const INVALID_STATUS = [401, 403, 409]

export const axios = Axios.create({
    baseURL: process.env.API_URL,
    withCredentials: true,
    withXSRFToken: true
})

const handleRedirect = (statusCode: number, url: string): void => {
    switch (statusCode) {
        // redirect user to log in when token expires due to idle
        case 401:
            setCookie('isAuthenticated', 'false')

            if (localStorage.getItem('vault_active_brand')) {
                localStorage.removeItem('vault_active_brand')
            }

            if (!window.location.href.includes('/login')) {
                window.location.href = '/login'
            }
            break
        case 403:
            if (!window.location.href.includes('/403')) {
                window.location.href = '/403'
            }

            break
        case 409:
            // this condition is for the debt left form secure cookie
            // 409 when authenticated user try to log in again
            if (url.includes('/login')) {
                setCookie('isAuthenticated', 'true')

                window.location.href = '/dashboard'
            }

            break

        default:
            break
    }
}

const fetcher = {
    get: async (url: string, config = {}) => {
        try {
            const response = await axios.get(url, config)
            return response.data
        } catch (error: any) {
            const { status } = error.response
            if (INVALID_STATUS.includes(status)) {
                handleRedirect(status, url)
            }
            throw error
        }
    },

    post: async (url: string, data?: any, config = {}) => {
        try {
            const response = await axios.post(url, data, config)
            return response.data
        } catch (error: any) {
            const { status } = error.response
            if (INVALID_STATUS.includes(status)) {
                handleRedirect(status, url)
            }

            throw error
        }
    },

    put: async (url: string, data?: any, config = {}) => {
        try {
            const response = await axios.put(url, data, config)
            return response.data
        } catch (error: any) {
            const { status } = error.response

            if (INVALID_STATUS.includes(status)) {
                handleRedirect(status, url)
            }

            throw error
        }
    },

    delete: async (url: string, config = {}) => {
        try {
            const response = await axios.delete(url, config)
            return response.data
        } catch (error: any) {
            const { status } = error.response

            if (INVALID_STATUS.includes(status)) {
                handleRedirect(status, url)
            }

            throw error
        }
    }
}

export const axiosPusherAuthorizer = (channel: Channel) => {
    return {
        authorize: (
            socketId: string,
            callback: ChannelAuthorizationCallback
        ) => {
            axios({
                method: 'GET',
                url: '/broadcasting/auth',
                params: {
                    channel_name: channel.name,
                    socket_id: socketId
                }
            })
                .then((response: any) => {
                    callback(null, response.data)
                })
                .catch((error: any) => {
                    callback(
                        new Error(
                            `Error authenticating with pusher server: ${error}`
                        ),
                        {
                            auth: ''
                        }
                    )
                })
        }
    }
}

export default fetcher
