import { yupResolver } from '@hookform/resolvers/yup';
import { Fragment, ReactNode, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Button, Dialog, DialogClose, DialogTrigger, Flex, Form, Input, MessageDialog, MessageDialogType, RadixDialogContent, Text } from 'ui';
import * as yup from 'yup';
import { LoaderIcon } from '../Svgs';
type FormData = {
  name: string;
};
const schema = yup.object({
  name: yup.string().required('Required')
}).required();
type Props = {
  isOpen: boolean;
  title: string;
  description: string;
  labelName: string;
  children?: ReactNode;
  initialName?: string;
  maxInputLength?: number;
  submitText?: string;
  submittingText?: string;
  submitAction(name: string): void;
  handleClose(): void;
  handleOpenDialog(): void;
};
export const CreateEditDialog = ({
  title,
  labelName,
  description,
  isOpen,
  children,
  initialName,
  maxInputLength,
  submitText = 'create',
  submittingText = 'Creating',
  submitAction,
  handleOpenDialog,
  handleClose
}: Props) => {
  const [messageDialog, setMessageDialog] = useState<MessageDialogType>(undefined);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const {
    register,
    reset,
    handleSubmit,
    watch,
    formState: {
      errors
    }
  } = useForm<FormData>({
    defaultValues: {
      name: initialName
    },
    resolver: yupResolver(schema)
  });
  const initialNameValue = watch('name');
  const handleCloseDialog = () => {
    handleClose();
    isSubmitting && setIsSubmitting(false);
    reset();
  };
  const onSubmit = handleSubmit(async data => {
    try {
      setIsSubmitting(true);
      await submitAction(data.name);
      reset();
      setIsSubmitting(false);
      handleClose();
    } catch (e: any) {
      // may be Jack can take over this type fix
      const code = e.response.status;
      let message;
      setIsSubmitting(false);
      if (code === 422) {
        message = `The email and password doesn't match.`;
      } else {
        message = `Failed to authenticate.`;
      }
      setMessageDialog({
        title: `Error`,
        description: message
      });
    }
  });
  const handleCloseMessageDialog = () => {
    setIsSubmitting(false);
    setMessageDialog(undefined);
    reset();
  };
  return <Dialog open={isOpen} data-sentry-element="Dialog" data-sentry-component="CreateEditDialog" data-sentry-source-file="CreateEditDialog.tsx">
            {children && <DialogTrigger onClick={handleOpenDialog} asChild>
                    {children}
                </DialogTrigger>}
            <RadixDialogContent title={title} description={description} handleCloseDialog={handleCloseDialog} data-sentry-element="RadixDialogContent" data-sentry-source-file="CreateEditDialog.tsx">
                <Form onSubmit={onSubmit} data-sentry-element="Form" data-sentry-source-file="CreateEditDialog.tsx">
                    <Input id="name-input" type="text" label={labelName} maxLength={maxInputLength && maxInputLength} containerCss={{
          mb: '$3'
        }} error={errors.name ? true : false} errorMsg={errors.name?.message} {...register('name')} autoFocus data-sentry-element="Input" data-sentry-source-file="CreateEditDialog.tsx" />
                    <Flex css={{
          height: '0rem',
          position: 'relative',
          bottom: '30rem',
          left: '5rem'
        }} justifyContent={'flexStart'} alignItems={'flexStart'} data-sentry-element="Flex" data-sentry-source-file="CreateEditDialog.tsx">
                        {maxInputLength && !errors.name && initialNameValue && initialNameValue.length ? <Text size={'2'} textAlign={'left'} css={{
            width: '100%'
          }}>
                                {maxInputLength - initialNameValue.length}{' '}
                                characters left
                            </Text> : null}
                    </Flex>
                    <Flex data-sentry-element="Flex" data-sentry-source-file="CreateEditDialog.tsx">
                        <DialogClose asChild onClick={handleCloseDialog} data-sentry-element="DialogClose" data-sentry-source-file="CreateEditDialog.tsx">
                            <Button color="secondary" size="lg" css={{
              mr: '10rem'
            }} data-sentry-element="Button" data-sentry-source-file="CreateEditDialog.tsx">
                                cancel
                            </Button>
                        </DialogClose>

                        <Button type="submit" color={isSubmitting ? 'loading' : 'primary'} size="lg" data-sentry-element="Button" data-sentry-source-file="CreateEditDialog.tsx">
                            {isSubmitting ? <Fragment>
                                    <LoaderIcon /> {submittingText}...
                                </Fragment> : submitText}
                        </Button>
                    </Flex>
                </Form>
            </RadixDialogContent>
            <MessageDialog messageDialog={messageDialog} handleCloseDialog={handleCloseMessageDialog} data-sentry-element="MessageDialog" data-sentry-source-file="CreateEditDialog.tsx" />
        </Dialog>;
};
export const useCreateEditDialog = (): {
  openDialog: boolean;
  setOpenDialog(val: boolean): void;
  handleOpenDialog(): void;
  handleCloseDialog(): void;
} => {
  const [openDialog, setOpenDialog] = useState(false);
  const handleOpenDialog = () => {
    setOpenDialog(true);
  };
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  return {
    openDialog,
    setOpenDialog,
    handleOpenDialog,
    handleCloseDialog
  };
};