import fetch from './fetch'

const fetchWithToken = async (url: string, token: string) => {
    return await fetch(`${url}`, {
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        }
    })
}

export default fetchWithToken
