import { yupResolver } from '@hookform/resolvers/yup';
import { Fragment } from 'react';
import { useForm } from 'react-hook-form';
import { Button, Dialog, Flex, Form, Input, RadixDialogContent, VStack } from 'ui';
import * as yup from 'yup';
import { LoaderIcon } from '../Svgs';
export type ChangePwFormData = {
  currentPassword: string;
  newPassword: string;
  confirmNewPassword: string;
};
type Props = {
  isOpen: boolean;
  isSubmitting: boolean;
  handleCloseDialog(): void;
  handleChangePassword(data: ChangePwFormData): void;
};
const ChangePasswordDialog = ({
  isOpen,
  isSubmitting,
  handleCloseDialog,
  handleChangePassword
}: Props) => {
  const schema = yup.object().shape({
    currentPassword: yup.string().min(8, 'Password length should be at least 8 characters').required('Current Password is required'),
    newPassword: yup.string().min(8, 'Minimal 8 characters').matches(new RegExp('(\\p{Ll}+.*\\p{Lu})|(\\p{Lu}+.*\\p{Ll})', 'u'), 'The password must contain at least one uppercase and one lowercase letter.').required('Password is required'),
    confirmNewPassword: yup.string().required('Confirm Password is required').min(8, 'Password length should be at least 8 characters').oneOf([yup.ref('newPassword')], 'Passwords do not match')
  }).required();
  const {
    register,
    handleSubmit,
    reset,
    formState: {
      errors
    }
  } = useForm<ChangePwFormData>({
    resolver: yupResolver(schema)
  });
  const onSubmit = handleSubmit(async data => {
    try {
      await handleChangePassword(data);
      reset();
    } catch (e: unknown) {
      reset();
    }
  });
  const handleClose = () => {
    reset();
    handleCloseDialog();
  };
  return <Dialog open={isOpen} data-sentry-element="Dialog" data-sentry-component="ChangePasswordDialog" data-sentry-source-file="ChangePassword.tsx">
            <RadixDialogContent title="Change Password" handleCloseDialog={handleClose} data-sentry-element="RadixDialogContent" data-sentry-source-file="ChangePassword.tsx">
                <Form onSubmit={onSubmit} data-sentry-element="Form" data-sentry-source-file="ChangePassword.tsx">
                    <VStack spacing="3" data-sentry-element="VStack" data-sentry-source-file="ChangePassword.tsx">
                        <Input id="current-pw-input" type="password" label="Current Password" error={errors.currentPassword ? true : false} errorMsg={errors.currentPassword?.message} {...register('currentPassword')} data-sentry-element="Input" data-sentry-source-file="ChangePassword.tsx" />
                        <Input id="new-pw-input" type="password" label="Enter New Password" error={errors.newPassword ? true : false} errorMsg={errors.newPassword?.message} {...register('newPassword')} data-sentry-element="Input" data-sentry-source-file="ChangePassword.tsx" />
                        <Input id="confirm-pw-input" type="password" label="Confirm New Password" error={errors.confirmNewPassword ? true : false} errorMsg={errors.confirmNewPassword?.message} {...register('confirmNewPassword')} data-sentry-element="Input" data-sentry-source-file="ChangePassword.tsx" />

                        <Flex data-sentry-element="Flex" data-sentry-source-file="ChangePassword.tsx">
                            <Button color="secondary" css={{
              mr: '$3'
            }} type="button" onClick={handleClose} data-sentry-element="Button" data-sentry-source-file="ChangePassword.tsx">
                                cancel
                            </Button>

                            <Button type="submit" color={isSubmitting ? 'loading' : 'primary'} rotateSvg={isSubmitting} data-sentry-element="Button" data-sentry-source-file="ChangePassword.tsx">
                                {isSubmitting ? <Fragment>
                                        <LoaderIcon /> updating...
                                    </Fragment> : 'confirm'}
                            </Button>
                        </Flex>
                    </VStack>
                </Form>
            </RadixDialogContent>
        </Dialog>;
};
export default ChangePasswordDialog;