import fetch from 'isomorphic-unfetch'
import { merge } from 'lodash'
import Router from 'next/router'

export interface FetchErrors extends Error {
    errors?: {
        [key: string]: string | string[]
    }
    message: string
    status_code?: number
}

export default async function FetchFunction<JSON = any>(
    input: RequestInfo,
    init?: RequestInit
): Promise<JSON> {
    let clientHeader = {}

    const res = await fetch(input, merge(init, clientHeader))
    const status_code = res.status

    if (res.status === 403) {
        Router.push('/403')
    }

    if (!res.ok) {
        const resError = await res.json()
        const error: FetchErrors = new Error(
            resError.message
                ? resError.message
                : `${resError.status_code} error, please contact us at hq@jogg.co.`
        )

        error.status_code = res.status
        error.errors = resError.errors ? resError.errors : undefined

        throw error
    }

    const result = await res.json()

    return { ...result, status_code }
}
