export const secondsToHHMMSS = (duration: number) => {
    if (Number(duration) === 0) return ''

    let hours: number | string = Math.floor(duration / 3600)
    let minutes: number | string = Math.floor((duration - hours * 3600) / 60)
    let seconds: number | string = Math.floor(
        duration - hours * 3600 - minutes * 60
    )

    if (hours < 10) {
        hours = '0' + hours
    }
    if (minutes < 10) {
        minutes = '0' + minutes
    }
    if (seconds < 10) {
        seconds = '0' + seconds
    }
    return hours + ':' + minutes + ':' + seconds
}

export const secondsToMMSS = (duration: number) => {
    if (Number(duration) === 0) return '00:00'

    let hours: number | string = Math.floor(duration / 3600)
    let minutes: number | string = Math.floor((duration - hours * 3600) / 60)
    let seconds: number | string = Math.floor(
        duration - hours * 3600 - minutes * 60
    )

    if (hours < 10) {
        if (hours > 0) {
            hours = '0' + hours + ':'
        }

        hours = ''
    }
    if (minutes < 10) {
        minutes = '0' + minutes
    }
    if (seconds < 10) {
        seconds = '0' + seconds
    }
    return `${hours}${minutes}:${seconds}`
}

export const convertTimeToSeconds = (time: string) => {
    const videoTime = time.split(':')
    return +videoTime[0] * 60 * 60 + +videoTime[1] * 60 + +videoTime[2]
}

export const convertTimeToMilliseconds = (time: string) => {
    const videoTime = time.split(':')
    return (
        +videoTime[0] * 60 * 60 * 1000 +
        +videoTime[1] * 60 * 1000 +
        +videoTime[2] * 1000
    )
}
