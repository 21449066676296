import { yupResolver } from '@hookform/resolvers/yup';
import { csrf } from 'auth';
import { Fragment } from 'react';
import { useForm } from 'react-hook-form';
import { Button, Dialog, Flex, Form, Input, LinkButton, RadixDialogContent } from 'ui';
import * as yup from 'yup';
import fetcher from '../../utils/fetcher';
import { MessageDialogType } from '../MessageDialog';
import { LoaderIcon } from '../Svgs';
type FormData = {
  email: string;
};
const schema = yup.object({
  email: yup.string().email('Invalid email address').required('Required')
}).required();
type Props = {
  isSubmitting: boolean;
  openResetPw: boolean;
  setOpenResetPw: (open: boolean) => void;
  setMessageDialog: (message: MessageDialogType) => void;
  setIsSubmitting: (submit: boolean) => void;
};
const ResetPwDialog = ({
  isSubmitting,
  openResetPw,
  setOpenResetPw,
  setMessageDialog,
  setIsSubmitting
}: Props) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: {
      errors
    }
  } = useForm<FormData>({
    resolver: yupResolver(schema)
  });
  const handleCloseDialog = () => {
    setOpenResetPw(false);
  };
  const handleOpenDialog = () => {
    setOpenResetPw(true);
  };
  const onSubmit = handleSubmit(async data => {
    setIsSubmitting(true);
    try {
      await csrf();
      await fetcher.post('/forgot-password', {
        email: data.email
      });
      handleCloseDialog();
      reset();
      setIsSubmitting(false);
      setMessageDialog({
        title: 'Success',
        description: 'Password reset link sent'
      });
    } catch (error: any) {
      if (error.isAxiosError) {
        setMessageDialog({
          title: `Error`,
          description: error.response.data.message
        });
      } else {
        alert(error);
      }
      setIsSubmitting(false);
    }
  });
  return <Dialog open={openResetPw} data-sentry-element="Dialog" data-sentry-component="ResetPwDialog" data-sentry-source-file="ResetPwDialog.tsx">
            <LinkButton type="button" size="sm" weight="normal" onClick={handleOpenDialog} data-sentry-element="LinkButton" data-sentry-source-file="ResetPwDialog.tsx">
                forgot your password?
            </LinkButton>
            <RadixDialogContent title="Reset Password" description="Enter the email address associated with your account and we will send you instructions on how to reset your password." data-sentry-element="RadixDialogContent" data-sentry-source-file="ResetPwDialog.tsx">
                <Form alignItems="center" onSubmit={onSubmit} data-sentry-element="Form" data-sentry-source-file="ResetPwDialog.tsx">
                    <Input id="reset-email-input" type="email" label="Email Address" maxLength={500} containerCss={{
          mb: '$3'
        }} error={errors.email ? true : false} errorMsg={errors.email?.message} {...register('email')} data-sentry-element="Input" data-sentry-source-file="ResetPwDialog.tsx" />
                    <Flex data-sentry-element="Flex" data-sentry-source-file="ResetPwDialog.tsx">
                        <Button type="button" color="secondary" size="lg" css={{
            mr: '10rem'
          }} onClick={handleCloseDialog} data-sentry-element="Button" data-sentry-source-file="ResetPwDialog.tsx">
                            cancel
                        </Button>

                        <Button type="submit" color={isSubmitting ? 'loading' : 'primary'} rotateSvg={isSubmitting} disabled={isSubmitting} data-sentry-element="Button" data-sentry-source-file="ResetPwDialog.tsx">
                            {isSubmitting ? <Fragment>
                                    <LoaderIcon /> sending...
                                </Fragment> : 'send'}
                        </Button>
                    </Flex>
                </Form>
            </RadixDialogContent>
        </Dialog>;
};
export default ResetPwDialog;