import { ElementRef, forwardRef } from 'react';
import { Text } from '.';
import { VariantProps, CSS } from '../../theme/stitches.config';
import { merge } from 'lodash';
const DEFAULT_TAG = 'span';
type AccentTextVariants = VariantProps<typeof Text>;
type AccentTextProps = React.ComponentProps<typeof DEFAULT_TAG> & AccentTextVariants & {
  css?: CSS;
  as?: any;
};
const defaultCss: CSS = {
  color: '$polo',
  fontSize: '$2'
};
export const AccentText = forwardRef<ElementRef<typeof DEFAULT_TAG>, AccentTextProps>((props, forwardRef) => {
  const {
    ...textProps
  } = props;
  return <Text {...textProps} ref={forwardRef} as={DEFAULT_TAG} css={{
    ...merge(defaultCss, props.css)
  }} />;
});
AccentText.displayName = 'AccentText';