import { blackA } from '@radix-ui/colors';
import * as DialogPrimitive from '@radix-ui/react-dialog';
import { keyframes } from '@stitches/react';
import { ComponentProps, ElementRef, forwardRef } from 'react';
import { CSS, styled } from '../../theme/stitches.config';
import { IconButton } from '../Button';
import { CrossIcon } from '../Svgs';
const overlayShow = keyframes({
  '0%': {
    opacity: 0
  },
  '100%': {
    opacity: 1
  }
});
const contentShow = keyframes({
  '0%': {
    opacity: 0,
    transform: 'translate(-50%, -48%) scale(.96)'
  },
  '100%': {
    opacity: 1,
    transform: 'translate(-50%, -50%) scale(1)'
  }
});
const StyledOverlay = styled(DialogPrimitive.Overlay, {
  backgroundColor: blackA.blackA11,
  position: 'fixed',
  inset: 0,
  zIndex: '10',
  '@media (prefers-reduced-motion: no-preference)': {
    animation: `${overlayShow} 150ms cubic-bezier(0.16, 1, 0.3, 1)`
  }
});
const StyledContent = styled(DialogPrimitive.Content, {
  $$contentMaxWidth: '400rem',
  backgroundColor: '$dark',
  color: '$white',
  borderRadius: '$1',
  position: 'fixed',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90vw',
  maxWidth: '$$contentMaxWidth',
  maxHeight: '85vh',
  padding: '20rem 25rem',
  boxShadow: '0rem 4rem 4rem 0rem #00000040',
  zIndex: '99',
  overflow: 'auto',
  '@media (prefers-reduced-motion: no-preference)': {
    animation: `${contentShow} 150ms cubic-bezier(0.16, 1, 0.3, 1)`
  },
  '&:focus': {
    outline: 'none'
  }
});
const DialogHeadingDiv = styled('div', {
  width: '100%',
  mb: '$4',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  variants: {
    justifyCenter: {
      true: {
        justifyContent: 'center'
      }
    }
  }
});
const StyledTitle = styled(DialogPrimitive.Title, {
  fontSize: '$4',
  color: '$white',
  letterSpacing: '.5rem',
  fontWeight: '$bold'
});
const StyledDescription = styled(DialogPrimitive.Description, {
  margin: '0',
  mb: '$2',
  fontSize: '$3',
  lineHeight: '20rem',
  letterSpacing: '.5rem'
});
const DEFAULT_TAG = 'div';
type DialogProps = {
  title?: string;
  description?: string;
  descriptionCss?: CSS;
  contentCss?: CSS;
  centerHeading?: boolean;
  onOverlayClick?(): void;
  handleCloseDialog?(): void;
  onInteractOutside?(): void;
  onOpenAutoFocus?(): void;
  onCloseAutoFocus?(): void;
  onPointerDownOutside?(): void;
  onEscapeKeyDown?(): void;
} & ComponentProps<typeof DEFAULT_TAG>;
export const RadixDialogContent = forwardRef<ElementRef<typeof DEFAULT_TAG>, DialogProps>(({
  children,
  title,
  centerHeading,
  description,
  descriptionCss,
  contentCss,
  onOverlayClick,
  handleCloseDialog,
  ...props
}, forwardedRef) => <DialogPrimitive.Portal>
            <StyledOverlay onClick={onOverlayClick} />
            <StyledContent {...props} ref={forwardedRef} aria-describedby={description} css={contentCss}>
                <DialogHeadingDiv justifyCenter={centerHeading}>
                    <StyledTitle>{title}</StyledTitle>
                    {handleCloseDialog ? <DialogPrimitive.Close asChild aria-label="Close" onClick={handleCloseDialog}>
                            <IconButton aria-label={'close dialog'} css={{
          $$iconWidth: '10rem',
          $$iconHeight: '10rem',
          $$svgMr: '0',
          position: 'absolute',
          right: '25rem',
          top: '25rem'
        }}>
                                <CrossIcon />
                            </IconButton>
                        </DialogPrimitive.Close> : null}
                </DialogHeadingDiv>
                <StyledDescription css={descriptionCss}>
                    {description}
                </StyledDescription>
                {children}
            </StyledContent>
        </DialogPrimitive.Portal>);
export const Dialog = DialogPrimitive.Root;
export const DialogTrigger = DialogPrimitive.Trigger;
export const DialogClose = DialogPrimitive.Close;
RadixDialogContent.displayName = 'RadixDialogContent';