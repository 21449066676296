import classNames from 'classnames';
import CategoryFolderIcon from 'share-svgs/svgs/folder-category.svg';
import FolderIcon from 'share-svgs/svgs/folder.svg';
import NotepadIcon from 'share-svgs/svgs/notepad.svg';
import VideoIcon from 'share-svgs/svgs/video-icon.svg';
import { Box, BreadcrumbButtonsItem, HStack, Text } from 'ui';
type Props = {
  id: string;
  name: string;
  type: 'subscription' | 'folder' | 'categories' | 'session' | 'notepad';
  isSelected?: boolean;
  isDisabled?: boolean;
  handleOnClick(item: BreadcrumbButtonsItem): void;
};
export const SelectBlock = ({
  id,
  name,
  type,
  isSelected,
  isDisabled,
  handleOnClick
}: Props) => {
  return <Box as="button" className={classNames({
    selected: isSelected,
    disabled: isDisabled
  })} css={{
    width: '100%',
    height: '100rem',
    transition: 'background .3s',
    '& svg': {
      height: '15rem',
      mt: '2rem',
      '& path': {
        fill: type === 'notepad' ? 'white' : '$cove'
      }
    },
    '&.selected': {
      boxShadow: 'inset 0px 0px 0px 1rem #A7B7D1',
      background: '$dark'
    },
    '&:hover': {
      background: '$cove',
      color: '$squid',
      '& svg path': {
        fill: '$squid'
      }
    },
    '&.disabled': {
      background: '$dark_gray',
      color: '$nobel',
      pointerEvents: 'none',
      '& svg path': {
        fill: '$nobel'
      }
    }
  }} p="2" bg={'dark_lighter'} bRadius={'1'} onClick={() => handleOnClick({
    id,
    name
  })} data-sentry-element="Box" data-sentry-component="SelectBlock" data-sentry-source-file="SelectBlock.tsx">
            <HStack alignItems={'flexStart'} spacing="1" data-sentry-element="HStack" data-sentry-source-file="SelectBlock.tsx">
                {type === 'folder' && <FolderIcon />}
                {type === 'categories' && <CategoryFolderIcon />}
                {type === 'session' && <VideoIcon />}
                {type === 'notepad' && <NotepadIcon />}
                <Text size="1" textTransform={'upper'} weight={'bold'} textAlign={'left'} data-sentry-element="Text" data-sentry-source-file="SelectBlock.tsx">
                    {name}
                </Text>
            </HStack>
        </Box>;
};