import { SVGProps } from 'react';
export const LoaderIcon = (props: SVGProps<SVGSVGElement>) => <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14" {...props} data-sentry-element="svg" data-sentry-component="LoaderIcon" data-sentry-source-file="LoaderIcon.tsx">
        <title>loader icon</title>
        <g fill="#999" clipPath="url(#a)" data-sentry-element="g" data-sentry-source-file="LoaderIcon.tsx">
            <path d="M6.741 4.316c1.107 0 2.005-.869 2.005-1.94 0-1.07-.898-1.938-2.005-1.938-1.107 0-2.005.868-2.005 1.939 0 1.07.898 1.939 2.005 1.939Z" data-sentry-element="path" data-sentry-source-file="LoaderIcon.tsx" />
            <path fillOpacity={0.9} d="M10.885 6.078c1.011 0 1.83-.793 1.83-1.771 0-.978-.819-1.771-1.83-1.771-1.011 0-1.831.793-1.831 1.77 0 .979.82 1.772 1.83 1.772Z" data-sentry-element="path" data-sentry-source-file="LoaderIcon.tsx" />
            <path fillOpacity={0.8} d="M11.91 10.249c.913 0 1.653-.716 1.653-1.599s-.74-1.598-1.653-1.598-1.653.715-1.653 1.598c0 .883.74 1.599 1.653 1.599Z" data-sentry-element="path" data-sentry-source-file="LoaderIcon.tsx" />
            <path fillOpacity={0.7} d="M9.036 13.563c.817 0 1.479-.64 1.479-1.431 0-.79-.662-1.43-1.48-1.43-.816 0-1.478.64-1.478 1.43 0 .79.662 1.43 1.479 1.43Z" data-sentry-element="path" data-sentry-source-file="LoaderIcon.tsx" />
            <path fillOpacity={0.6} d="M4.434 13.39c.721 0 1.306-.565 1.306-1.263 0-.697-.585-1.262-1.306-1.262-.72 0-1.305.565-1.305 1.262 0 .698.584 1.263 1.305 1.263Z" data-sentry-element="path" data-sentry-source-file="LoaderIcon.tsx" />
            <path fillOpacity={0.5} d="M1.565 9.732c.622 0 1.127-.488 1.127-1.09 0-.602-.505-1.09-1.127-1.09-.623 0-1.127.488-1.127 1.09 0 .602.504 1.09 1.127 1.09Z" data-sentry-element="path" data-sentry-source-file="LoaderIcon.tsx" />
            <path fillOpacity={0.4} d="M2.598 5.225c.526 0 .953-.413.953-.922 0-.51-.427-.922-.953-.922-.527 0-.953.412-.953.922 0 .509.426.922.953.922Z" data-sentry-element="path" data-sentry-source-file="LoaderIcon.tsx" />
        </g>
        <defs data-sentry-element="defs" data-sentry-source-file="LoaderIcon.tsx">
            <clipPath id="a" data-sentry-element="clipPath" data-sentry-source-file="LoaderIcon.tsx">
                <path fill="#fff" d="M0 0h14v14H0z" data-sentry-element="path" data-sentry-source-file="LoaderIcon.tsx" />
            </clipPath>
        </defs>
    </svg>;