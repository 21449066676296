import { yupResolver } from '@hookform/resolvers/yup';
import { csrf } from 'auth';
import { useRouter } from 'next/router';
import { Fragment, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Box, Button, Flex, Form, Heading, Input, MessageDialog, MessageDialogType, VStack } from 'ui';
import * as yup from 'yup';
import fetcher from '../../utils/fetcher';
import { LoaderIcon } from '../Svgs';
const PASSWORD_MAX_LENGTH = 50;
type FormData = {
  newPassword: string;
  confirmNewPassword: string;
};
const schema = yup.object({
  newPassword: yup.string().min(8, 'Password length should be at least 8 characters').max(PASSWORD_MAX_LENGTH).required('New Password is required'),
  confirmNewPassword: yup.string().required('Confirm Password is required').min(8, 'Password length should be at least 8 characters').max(PASSWORD_MAX_LENGTH).oneOf([yup.ref('newPassword')], 'Passwords do not match')
}).required();
export const ResetPassword = () => {
  const router = useRouter();
  const {
    token,
    email
  } = router.query;
  const {
    register,
    handleSubmit,
    reset,
    formState: {
      errors
    }
  } = useForm<FormData>({
    resolver: yupResolver(schema)
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [messageDialog, setMessageDialog] = useState<MessageDialogType>(undefined);
  const handleCloseMessageDialog = () => {
    setMessageDialog(undefined);
  };
  const onSubmit = handleSubmit(async data => {
    setIsSubmitting(true);
    try {
      await csrf();
      await fetcher.post('/reset-password', {
        password: data.newPassword,
        password_confirmation: data.confirmNewPassword,
        email: `${email}`,
        token: `${token}`
      });
      setIsSubmitting(false);
      setMessageDialog({
        title: 'Success',
        description: `You've successfully reset password.`,
        onClick: async () => {
          await router.replace('/login');
        }
      });
      reset();
    } catch (error: any) {
      if (error.isAxiosError) {
        setMessageDialog({
          title: `Error`,
          description: error.response.data.message
        });
      } else {
        alert(error);
      }
      setIsSubmitting(false);
      reset();
    }
  });
  const handleBackButtonClick = () => {
    router.back();
  };
  return <Box bg="dark" padding="lg" bRadius="1" alignItems="center" css={{
    width: '100%',
    maxWidth: '650rem',
    m: '0 auto',
    py: '40rem',
    position: 'fixed',
    top: '50%',
    right: 0,
    left: 0,
    transform: 'translateY(-50%)'
  }} boxShadowed data-sentry-element="Box" data-sentry-component="ResetPassword" data-sentry-source-file="ResetPassword.tsx">
            <Form onSubmit={onSubmit} data-sentry-element="Form" data-sentry-source-file="ResetPassword.tsx">
                <VStack alignItems="flexStart" data-sentry-element="VStack" data-sentry-source-file="ResetPassword.tsx">
                    <Heading data-sentry-element="Heading" data-sentry-source-file="ResetPassword.tsx">Reset Password</Heading>
                    <Input id="new-pw-input" type="password" label="Enter New Password" maxLength={PASSWORD_MAX_LENGTH} error={errors.newPassword ? true : false} errorMsg={errors.newPassword?.message} {...register('newPassword')} data-sentry-element="Input" data-sentry-source-file="ResetPassword.tsx" />
                    <Input id="confirm-pw-input" type="password" label="Confirm New Password" maxLength={PASSWORD_MAX_LENGTH} error={errors.confirmNewPassword ? true : false} errorMsg={errors.confirmNewPassword?.message} {...register('confirmNewPassword')} data-sentry-element="Input" data-sentry-source-file="ResetPassword.tsx" />
                </VStack>

                <Flex css={{
        mt: '50rem'
      }} justifyContent="spaceBetween" data-sentry-element="Flex" data-sentry-source-file="ResetPassword.tsx">
                    <Button type="button" color="secondary" onClick={handleBackButtonClick} data-sentry-element="Button" data-sentry-source-file="ResetPassword.tsx">
                        back
                    </Button>
                    <Button type="submit" color={isSubmitting ? 'loading' : 'primary'} rotateSvg={isSubmitting} disabled={isSubmitting} data-sentry-element="Button" data-sentry-source-file="ResetPassword.tsx">
                        {isSubmitting ? <Fragment>
                                <LoaderIcon /> saving...
                            </Fragment> : 'save'}
                    </Button>
                </Flex>
            </Form>
            <MessageDialog messageDialog={messageDialog} handleCloseDialog={handleCloseMessageDialog} data-sentry-element="MessageDialog" data-sentry-source-file="ResetPassword.tsx" />
        </Box>;
};