import { create } from 'zustand';
import { persist } from 'zustand/middleware';
interface SearchPreferenceState {
  searchPreference: 'exact' | 'partial';
  setSearchPreference: (newSearchPreference: 'exact' | 'partial') => void;
}
export const useCurrentSearchPreference = create<SearchPreferenceState>()(persist(set => ({
  searchPreference: 'partial',
  setSearchPreference: newSearchPreference => set(state => ({
    ...state,
    searchPreference: newSearchPreference
  }))
}), {
  name: 'current_search_preference'
}));