import { styled } from '../../theme/stitches.config';
export const BreadcrumbLink = styled('a', {
  fontSize: '$2',
  color: '$cove',
  fontWeight: '$bold',
  letterSpacing: '$1',
  textTransform: 'uppercase',
  textDecoration: 'none',
  cursor: 'pointer',
  display: 'flex',
  '&:focus': {
    outlineWidth: '1rem',
    outlineStyle: 'dashed',
    outlineOffset: '1rem',
    textDecorationLine: 'none'
  }
});