import { styled } from '../../theme/stitches.config';
export const Avatar = styled('img', {
  flexShrink: '0',
  borderRadius: '$round',
  variants: {
    objectFit: {
      cover: {
        objectFit: 'cover'
      },
      contain: {
        objectFit: 'contain'
      }
    }
  }
});