import { yupResolver } from '@hookform/resolvers/yup';
import { useAuth } from 'auth';
import { useRouter } from 'next/router';
import { Fragment, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Box, Button, Divider, Form, HStack, Heading, Input, LinkButton, TwoFactor, VStack } from 'ui';
import fetcher from 'ui/utils/fetcher';
import * as yup from 'yup';
import { MessageDialog, MessageDialogType } from '../MessageDialog';
import { LoaderIcon } from '../Svgs';
import ChangePasswordDialog, { ChangePwFormData } from './ChangePassword';
type FormData = {
  first_name: string;
  last_name: string;
  email: string;
};
const schema = yup.object({
  first_name: yup.string().required('Required'),
  last_name: yup.string().required('Required')
}).required();
type Props = {
  logoutHandler(): void;
};
export const AccountSetting = ({
  logoutHandler
}: Props) => {
  const {
    user
  } = useAuth();
  const router = useRouter();
  const {
    register,
    handleSubmit,
    formState: {
      errors
    },
    setValue
  } = useForm<FormData>({
    resolver: yupResolver(schema)
  });
  useEffect(() => {
    if (user) {
      setValue('first_name', user.data.first_name);
      setValue('last_name', user.data.last_name);
      setValue('email', user.data.email);
    }
  }, [user]);
  const [isChangePwDialogOpen, setIsChangePwDialogOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [messageDialog, setMessageDialog] = useState<MessageDialogType>(undefined);
  const handleCloseMessageDialog = () => {
    setMessageDialog(undefined);
  };
  const handleOpenDialog = () => {
    setIsChangePwDialogOpen(true);
  };
  const handleCloseDialog = () => {
    setIsChangePwDialogOpen(false);
  };
  const onSubmit = handleSubmit(async data => {
    setIsSubmitting(true);
    try {
      await fetcher.put(`/users/${user.data.id}`, {
        ...data
      });
      setMessageDialog({
        title: 'Success',
        description: 'We have successfully updated your information.',
        onClick: () => router.back()
      });
      setIsSubmitting(false);
    } catch (error: any) {
      if (error.isAxiosError) {
        setMessageDialog({
          title: `Error`,
          description: error.response.data.message
        });
      } else {
        alert(error);
      }
      setIsSubmitting(false);
    }
  });
  const handleChangePassword = async (data: ChangePwFormData) => {
    setIsSubmitting(true);
    try {
      await fetcher.put(`/users/${user.data.id}/password`, {
        current_password: data.currentPassword,
        new_password: data.newPassword,
        new_password_confirmation: data.confirmNewPassword
      });
      setMessageDialog({
        title: 'Success',
        description: 'We have successfully updated your password.'
      });
      logoutHandler();
      handleCloseDialog();
      setIsSubmitting(false);
    } catch (error: any) {
      if (error.isAxiosError) {
        setMessageDialog({
          title: `Error`,
          description: error.response.data.message
        });
      } else {
        alert(error);
      }
      setIsSubmitting(false);
    }
  };
  return <Box gap={'3'} padding="lg" data-sentry-element="Box" data-sentry-component="AccountSetting" data-sentry-source-file="AccountSetting.tsx">
            <Form onSubmit={onSubmit} data-sentry-element="Form" data-sentry-source-file="AccountSetting.tsx">
                <VStack alignItems="flexStart" data-sentry-element="VStack" data-sentry-source-file="AccountSetting.tsx">
                    <Heading css={{
          mb: '$4'
        }} data-sentry-element="Heading" data-sentry-source-file="AccountSetting.tsx">
                        Account Settings
                    </Heading>

                    <HStack spacing="5" data-sentry-element="HStack" data-sentry-source-file="AccountSetting.tsx">
                        <Input id="first-name" type="text" label="First Name" error={errors.first_name ? true : false} errorMsg={errors.first_name?.message} animatedLabel {...register('first_name')} data-sentry-element="Input" data-sentry-source-file="AccountSetting.tsx" />
                        <Input id="last-name" type="text" label="Last Name" error={errors.last_name ? true : false} errorMsg={errors.last_name?.message} {...register('last_name')} animatedLabel data-sentry-element="Input" data-sentry-source-file="AccountSetting.tsx" />
                    </HStack>

                    <Input id="email" type="text" label="Email" {...register('email')} animatedLabel data-sentry-element="Input" data-sentry-source-file="AccountSetting.tsx" />

                    <Button type="submit" color={isSubmitting ? 'loading' : 'primary'} rotateSvg={isSubmitting} disabled={isSubmitting} data-sentry-element="Button" data-sentry-source-file="AccountSetting.tsx">
                        {isSubmitting ? <Fragment>
                                <LoaderIcon /> saving...
                            </Fragment> : 'save'}
                    </Button>

                    <LinkButton type="button" size="sm" onClick={handleOpenDialog} data-sentry-element="LinkButton" data-sentry-source-file="AccountSetting.tsx">
                        change password
                    </LinkButton>
                </VStack>
            </Form>

            <Divider data-sentry-element="Divider" data-sentry-source-file="AccountSetting.tsx" />

            {user && <Box>
                    <TwoFactor twoFactorEnabled={user?.data.two_factor_auth_enabled} />
                </Box>}

            <ChangePasswordDialog isOpen={isChangePwDialogOpen} isSubmitting={isSubmitting} handleCloseDialog={handleCloseDialog} handleChangePassword={handleChangePassword} data-sentry-element="ChangePasswordDialog" data-sentry-source-file="AccountSetting.tsx" />

            <MessageDialog messageDialog={messageDialog} handleCloseDialog={handleCloseMessageDialog} data-sentry-element="MessageDialog" data-sentry-source-file="AccountSetting.tsx" />
        </Box>;
};