export const convertSecondsToHHMMSS = (seconds: number) => {
    const hours = Math.floor(seconds / 3600)
    const minutes = Math.floor((seconds % 3600) / 60)
    const remainingSeconds = seconds % 60

    const formattedHours = hours.toString().padStart(2, '0')
    const formattedMinutes = minutes.toString().padStart(2, '0')
    const formattedSeconds = remainingSeconds.toString().padStart(2, '0')

    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`
}

export const timeStringToSecondsNum = (timeString: string) => {
    const formattedStringArr = timeString.split(':')

    const totalSeconds =
        +formattedStringArr[0] * 3600 +
        +formattedStringArr[1] * 60 +
        +formattedStringArr[2]

    return totalSeconds
}
