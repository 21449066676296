import { Fragment } from 'react';
import { Flex, Text } from 'ui';
export type BreadcrumbButtonsItem = {
  id: string;
  name: string;
};
type Props = {
  breadcrumbList: BreadcrumbButtonsItem[];
  setBreadcrumbList(list: BreadcrumbButtonsItem[]): void;
};
export const BreadcrumbButtons = ({
  breadcrumbList,
  setBreadcrumbList
}: Props) => {
  const handleSliceList = (id: string) => {
    const currentNameIndex = breadcrumbList.findIndex(item => item.id === id);
    setBreadcrumbList([...breadcrumbList.slice(0, currentNameIndex + 1)]);
  };
  return <Flex justifyContent="flexStart" data-sentry-element="Flex" data-sentry-component="BreadcrumbButtons" data-sentry-source-file="BreadcrumbButtons.tsx">
            {breadcrumbList.map((item: BreadcrumbButtonsItem, index: number) => {
      const currentIndex = index + 1;
      if (breadcrumbList.length !== currentIndex) {
        return <Fragment key={`breadcrumb-buttons-${currentIndex}`}>
                                <button type="button" onClick={() => handleSliceList(item.id)}>
                                    <Text size="2" weight="bold" textTransform="upper" color="cove">
                                        {item.name}
                                    </Text>
                                </button>

                                <Text color="cove" weight="light" css={{
            m: '0 $2'
          }} size="2">
                                    /
                                </Text>
                            </Fragment>;
      }
      return <Text key={`breadcrumb-buttons-${currentIndex}`} size="2" weight="bold" textTransform="upper" color="sky">
                            {item.name}
                        </Text>;
    })}
        </Flex>;
};