import { ReactNode } from 'react';
import { CSS, styled, VariantProps } from '../../theme/stitches.config';
import { Text } from '../Text';
const DEFAULT_TAG = 'div';
type BannerProps = {
  title?: string;
  description: ReactNode;
} & VariantProps<typeof BannerContainer> & React.ComponentProps<typeof DEFAULT_TAG> & {
  css?: CSS;
  as?: any;
};
const BannerContainer = styled('div', {
  $$customSpacing: '',
  width: '100%',
  ml: 'auto',
  mr: 'auto',
  p: '10rem 20rem',
  borderRadius: '$1',
  display: 'flex',
  flexDirection: 'column',
  '& > :not(:first-child)': {
    mt: '$2'
  },
  variants: {
    variant: {
      error: {
        border: '1rem solid $orange',
        background: 'rgba(183, 72, 72, 0.1)'
      },
      success: {
        border: '1rem solid $boston',
        background: 'rgba(63, 148, 177, 0.10)'
      },
      info: {
        border: '1rem solid $boston',
        background: 'rgba(99, 185, 215, 0.13)'
      },
      note: {
        border: '1rem solid $dark_lighter',
        background: '$dark_lighter'
      }
    },
    maxWidth: {
      sm: {
        maxWidth: '$sm'
      },
      md: {
        maxWidth: '$md'
      },
      lg: {
        maxWidth: '$lg'
      }
    },
    spacing: {
      '1': {
        m: '$1 auto'
      },
      '2': {
        m: '$2 auto'
      },
      '3': {
        m: '$3 auto'
      },
      '4': {
        m: '$4 auto'
      },
      '5': {
        m: '$5 auto'
      },
      '6': {
        m: '$6 auto'
      },
      '7': {
        m: '$7 auto'
      },
      '8': {
        m: '$8 auto'
      },
      '9': {
        m: '$9 auto'
      },
      '10': {
        m: '$10 auto'
      },
      custom: {
        m: '$$customSpacing'
      }
    },
    noBottomMargin: {
      true: {
        mb: '0'
      }
    }
  },
  defaultVariants: {
    spacing: '3'
  }
});
export const Banner = ({
  title,
  description,
  css,
  ...props
}: BannerProps) => <BannerContainer {...props} css={css} data-sentry-element="BannerContainer" data-sentry-component="Banner" data-sentry-source-file="Banner.tsx">
        {title && <Text weight="bold" textTransform="upper">
                {title}
            </Text>}
        {description}
    </BannerContainer>;