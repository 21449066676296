export const formatAtUTCTimeZone = (date: string) => {
    return `${new Intl.DateTimeFormat('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        timeZone: 'UTC'
    })
        .format(new Date(date))
        .replace(/\//g, '-')} 
        at
        ${new Intl.DateTimeFormat('en-US', {
            hour: '2-digit',
            minute: '2-digit',
            timeZone: 'UTC'
        }).format(new Date(date))}`
}
