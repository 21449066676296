import { ElementRef, forwardRef } from 'react';
import { Text } from '.';
import { VariantProps, CSS } from '../../theme/stitches.config';
import { merge } from 'lodash';
const DEFAULT_TAG = 'span';
type SmallTextVariants = VariantProps<typeof Text>;
type SmallTextProps = React.ComponentProps<typeof DEFAULT_TAG> & SmallTextVariants & {
  css?: CSS;
  as?: any;
};
const defaultCss: CSS = {
  fontSize: '$1'
};
export const SmallText = forwardRef<ElementRef<typeof DEFAULT_TAG>, SmallTextProps>((props, forwardRef) => {
  const {
    ...textProps
  } = props;
  return <Text {...textProps} ref={forwardRef} as={DEFAULT_TAG} css={{
    ...merge(defaultCss, props.css)
  }} />;
});
SmallText.displayName = 'SmallText';