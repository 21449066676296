import { ElementRef, forwardRef } from 'react';
import { Text } from '.';
import { VariantProps, CSS } from '../../theme/stitches.config';
import { merge } from 'lodash';
const DEFAULT_TAG = 'label';
type LabelTextVariants = VariantProps<typeof Text>;
type LabelTextProps = React.ComponentProps<typeof DEFAULT_TAG> & LabelTextVariants & {
  css?: CSS;
  as?: any;
};
const defaultCss: CSS = {
  color: '$gray',
  letterSpacing: '$1',
  textTransform: 'uppercase',
  fontWeight: '$bold',
  fontSize: '$2'
};
export const LabelText = forwardRef<ElementRef<typeof DEFAULT_TAG>, LabelTextProps>((props, forwardRef) => {
  const {
    ...textProps
  } = props;
  return <Text {...textProps} ref={forwardRef} as={DEFAULT_TAG} css={{
    ...merge(defaultCss, props.css)
  }} />;
});
LabelText.displayName = 'LabelText';