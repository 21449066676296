import { styled } from '../../theme/stitches.config';
export const Form = styled('form', {
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  variants: {
    alignItems: {
      center: {
        alignItems: 'center'
      },
      flexStart: {
        alignItems: 'flex-start'
      },
      flexEnd: {
        alignItems: 'flex-end'
      }
    }
  }
});