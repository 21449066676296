import { truncate } from 'lodash';
import { MutableRefObject } from 'react';
import { CSS, styled } from '../../theme/stitches.config';
type FileInputProps = {
  id: string;
  fileInputRef: MutableRefObject<HTMLInputElement | null>;
  fileName?: string;
  testId?: string;
  accept?: string;
  reactFormRef?: (instance: any) => void;
} & {
  css?: CSS;
  as?: any;
};
export const FileInput = ({
  id,
  as,
  css,
  fileName,
  fileInputRef,
  reactFormRef,
  accept = 'video/mp4,video/x-m4v,video/*,audio/*',
  testId,
  ...rest
}: FileInputProps) => {
  const handleChooseFileClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };
  const clearInputValue = (event: any) => {
    event.target.value = '';
  };
  return <FileInputContainerDiv as={as} data-testid={testId} css={css} data-sentry-element="FileInputContainerDiv" data-sentry-component="FileInput" data-sentry-source-file="FileInput.tsx">
            <FileInputLabel htmlFor={id} data-sentry-element="FileInputLabel" data-sentry-source-file="FileInput.tsx">
                choose an audio or video file to upload
            </FileInputLabel>
            <FileInputButton type="button" onClick={handleChooseFileClick} data-sentry-element="FileInputButton" data-sentry-source-file="FileInput.tsx">
                <div className="name">
                    {truncate(fileName, {
          length: 56
        })}
                </div>
                <div className="choose-file">choose file</div>
            </FileInputButton>

            <input id={id} ref={e => {
      reactFormRef && reactFormRef(e);
      fileInputRef.current = e;
    }} type="file" accept={accept} onClick={clearInputValue} hidden {...rest} />
        </FileInputContainerDiv>;
};
const FileInputContainerDiv = styled('div', {
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start'
});
const FileInputLabel = styled('label', {
  fontSize: '$2',
  color: '$cyan',
  fontWeight: '$bold',
  textTransform: 'uppercase',
  letterSpacing: '$1'
});
const FileInputButton = styled('button', {
  width: '100%',
  display: 'flex',
  alignItems: 'stretch',
  outline: 'none',
  '.name': {
    maxWidth: '200rem',
    paddingLeft: '10rem',
    fontSize: '$2',
    border: '1rem solid $kashmir',
    borderTopLeftRadius: '6rem',
    borderBottomLeftRadius: '6rem',
    borderRight: 'none',
    color: '$cyan',
    display: 'flex',
    alignItems: 'center',
    flex: 1,
    background: '$dark_lighter',
    wordBreak: 'break-word',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontWeight: '$normal',
    '@md': {
      maxWidth: '100%'
    }
  },
  '.choose-file': {
    padding: '10rem 15rem',
    fontSize: '12rem',
    color: '$black',
    fontWeight: '$bold',
    letterSpacing: '1.5rem',
    textTransform: 'uppercase',
    borderTopRightRadius: '6rem',
    borderBottomRightRadius: '6rem',
    background: 'linear-gradient(180deg, #63b9d7 0%, #4698b4 100%)'
  }
});
FileInput.displayName = 'FileInput';