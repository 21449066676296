import { yupResolver } from '@hookform/resolvers/yup';
import { useActiveBrandId, useAuth } from 'auth';
import { isEmpty } from 'lodash';
import { useRouter } from 'next/router';
import { Fragment, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import OtpInput from 'react-otp-input';
import { Button, Container, Flex, Form, Heading, Input, Text } from 'ui';
import { AuthFlow, BrandDetails } from 'ui/types';
import fetcher, { axios } from 'ui/utils/fetcher';
import * as yup from 'yup';
import { HStack } from '../Layout';
import { LinkButton } from '../Link';
import { MessageDialog, MessageDialogType } from '../MessageDialog';
import { LoaderIcon } from '../Svgs';
import ResetPwDialog from './ResetPwDialog';
type FormData = {
  email: string;
  password: string;
  two_factor_code?: string;
  recovery_code?: string;
};
export const VAULT_APP_NAME = 'Statemints Vault';
export const STATEMINTS_APP_NAME = 'Statemints';
const EMAIL_MAX_LENGTH = 500;
const PASSWORD_MAX_LENGTH = 50;
const schema = yup.object({
  email: yup.string().email('Invalid email address').required('Required').max(EMAIL_MAX_LENGTH),
  password: yup.string().min(8).max(PASSWORD_MAX_LENGTH).required('Password is required')
}).required();
type LoginView = 'login' | 'authenticated-code' | 'recovery-code';
type Props = {
  appName: string;
  allowSignUp?: boolean;
  stopRedirectAfterLogin?: boolean;
  setAuthFlow?(view: AuthFlow): void;
};
export const LoginBox = ({
  appName,
  allowSignUp,
  setAuthFlow
}: Props) => {
  const router = useRouter();
  const {
    brandId
  } = router.query;
  const {
    activeBrandId,
    setActiveBrandId
  } = useActiveBrandId();
  const {
    login
  } = useAuth();
  const [loginView, setLoginView] = useState<LoginView>('login');
  const [isVerifying, setIsVerifying] = useState(false);
  const [recoveryCode, setRecoveryCode] = useState('');
  const [authenticatorCode, setAuthenticatorCode] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [messageDialog, setMessageDialog] = useState<MessageDialogType>(undefined);
  const [openResetPw, setOpenResetPw] = useState(false);
  const {
    register,
    handleSubmit,
    formState: {
      errors
    }
  } = useForm<FormData>({
    resolver: yupResolver(schema)
  });
  const handleCloseMessageDialog = () => {
    setMessageDialog(undefined);
  };
  const resetState = () => {
    setMessageDialog(undefined);
    setIsSubmitting(false);
  };
  const handleSignUpRedirect = () => {
    if (router.pathname.includes('/splash') && setAuthFlow) {
      setAuthFlow('sign-up');
      return;
    }
    router.push('/signup');
  };
  const handleLoggedInRedirect = () => {
    router.push('/dashboard');
    return;
  };
  const onSubmit = handleSubmit(async data => {
    setIsSubmitting(true);
    setIsVerifying(true);
    const loginPayload: {
      email: string;
      password: string;
      two_factor_code?: string;
      recovery_code?: string;
    } = {
      email: data.email,
      password: data.password
    };
    if (authenticatorCode.length === 6) {
      loginPayload.two_factor_code = authenticatorCode;
    }
    if (recoveryCode.length === 12) {
      loginPayload.recovery_code = recoveryCode;
    }
    try {
      await login(loginPayload, brandId ? `${brandId}` : undefined);
      const userResult = await fetcher.get(`/auth-user?include[]=${process.env.APP_NAME === 'statemints' ? 'subscribed_brands' : 'brands'}`);
      if (appName === VAULT_APP_NAME) {
        if (!userResult.data.brands.length || isEmpty(userResult.data.brands)) {
          await router.replace('/dashboard/welcome');
        } else {
          !activeBrandId && setActiveBrandId(userResult.data.brands[0].id);
          await router.replace(`/dashboard/brand/${activeBrandId ? activeBrandId : userResult.data.brands[0].id}`);
        }
        setIsSubmitting(false);
        return;
      }
      if (appName === STATEMINTS_APP_NAME) {
        const isAStatemintsUser = userResult.data.roles?.find((role: {
          id: string;
          name: string;
        }) => role.name === 'statemints');
        if (!isAStatemintsUser) {
          await router.replace(`/403`);
        }
        if (router.pathname.includes('/splash')) {
          // there is similar check on splash SSR which is for when user is in authernticated status
          // below is for when user is in unauthed status
          const user = await axios.get(`/auth-user?include[]=subscribed_brands`);
          const isSubscribed = user.data.data.subscribed_brands.find((brand: BrandDetails) => brand.id === brandId);
          if (isSubscribed) {
            router.push('/dashboard');
            return;
          }
          setAuthFlow && setAuthFlow('accept-invite');
          return;
        }
        await router.replace('/dashboard');
        setTimeout(() => {
          resetState();
        }, 500);
      }
      setRecoveryCode('');
      setAuthenticatorCode('');
    } catch (error: any) {
      if (error.isAxiosError) {
        const code = error.response.status;
        let message;
        setIsSubmitting(false);
        if (error.response.data.message === 'Needs two factor authentication') {
          return setLoginView('authenticated-code');
        }
        if (code === 422) {
          message = `The email and password doesn't match.`;
        } else if (code === 409) {
          handleLoggedInRedirect();
          return;
        } else {
          message = `Failed to authenticate.`;
        }
        setMessageDialog({
          title: `Error`,
          description: message
        });
      } else {
        alert(error);
      }
    } finally {
      setIsVerifying(false);
    }
  });
  useEffect(() => {
    if (authenticatorCode.length === 6 || recoveryCode.length === 12) {
      onSubmit();
    }
  }, [authenticatorCode, recoveryCode]);
  return <Fragment>
            <Heading css={{
      mb: '$4',
      textAlign: 'center'
    }} size="1" data-sentry-element="Heading" data-sentry-source-file="LoginBox.tsx">
                Log in to {appName}
            </Heading>

            {loginView === 'login' && <Container size="sm">
                    <Form alignItems="center" onSubmit={onSubmit}>
                        <Input id="login-input" type="email" label="Email Address" maxLength={EMAIL_MAX_LENGTH} error={errors.email ? true : false} errorMsg={errors.email?.message} {...register('email')} autoFocus />
                        <Input id="password-input" type="password" label="Password" maxLength={PASSWORD_MAX_LENGTH} containerCss={{
          mb: '$4'
        }} error={errors.password ? true : false} errorMsg={errors.password?.message} {...register('password')} />
                        <Button type="submit" color={isSubmitting ? 'loading' : 'primary'} size="lg" css={{
          mb: '$3'
        }} rotateSvg={isSubmitting}>
                            {isSubmitting ? <Fragment>
                                    <LoaderIcon /> logging...
                                </Fragment> : 'log in'}
                        </Button>
                    </Form>

                    <Flex justifyContent={'center'} direction={'col'} gap={'2'} css={{
        '@md': {
          flexDirection: 'row',
          gap: '20rem'
        }
      }}>
                        <ResetPwDialog openResetPw={openResetPw} isSubmitting={isSubmitting} setOpenResetPw={setOpenResetPw} setIsSubmitting={setIsSubmitting} setMessageDialog={setMessageDialog} />
                        {allowSignUp ? <HStack widthAuto spacing={'1'}>
                                <Text textTransform={'upper'} size={'2'}>
                                    {`don't have an account?`}{' '}
                                </Text>
                                <LinkButton type="button" size="sm" weight="normal" onClick={handleSignUpRedirect}>
                                    sign up
                                </LinkButton>
                            </HStack> : null}
                    </Flex>
                </Container>}

            {loginView === 'authenticated-code' && <Flex direction={'col'} gap="6">
                    <Text>Authentication Code</Text>
                    <OtpInput value={authenticatorCode} onChange={setAuthenticatorCode} numInputs={6} containerStyle={{
        display: 'flex',
        gap: '10rem'
      }} inputStyle={{
        backgroundColor: '#2A3647',
        width: '40rem',
        height: '50rem',
        border: '0',
        borderRadius: '6rem',
        color: 'white'
      }} renderInput={(props: any) => <input {...props} />} />

                    <Button disabled={authenticatorCode.length < 6 || isVerifying} onClick={() => onSubmit()}>
                        {isVerifying ? 'Verifying...' : 'Verify'}
                    </Button>
                    <Flex direction="col" alignItems={'flexStart'} gap="3">
                        <Text>
                            Open your two-factor authenticator app or browser
                            extension to view your authentication code.
                        </Text>
                        <LinkButton type="button" size="sm" weight="normal" onClick={() => {
          setLoginView('recovery-code');
        }}>
                            Use recovery code instead
                        </LinkButton>
                    </Flex>
                </Flex>}

            {loginView === 'recovery-code' && <Flex direction={'col'} gap="6">
                    <Text>Recovery Code</Text>
                    <OtpInput value={recoveryCode} onChange={setRecoveryCode} numInputs={12} containerStyle={{
        display: 'flex',
        gap: '10rem'
      }} inputStyle={{
        backgroundColor: '#2A3647',
        width: '40rem',
        height: '50rem',
        border: '0',
        borderRadius: '6rem',
        color: 'white'
      }} renderInput={(props: any) => <input {...props} />} />
                    <Button disabled={recoveryCode.length < 12 || isVerifying} onClick={() => onSubmit()}>
                        {isVerifying ? 'Verifying' : 'Verify'}
                    </Button>
                    <Flex alignItems={'flexStart'} direction={'col'} gap="3">
                        <Text>
                            If you are unable to access your mobile device,
                            enter one of your recovery codes to verify your
                            identity.
                        </Text>
                        <LinkButton type="button" size="sm" weight="normal" onClick={() => {
          setLoginView('authenticated-code');
        }}>
                            Use authentication app instead
                        </LinkButton>
                    </Flex>
                </Flex>}

            <MessageDialog messageDialog={messageDialog} handleCloseDialog={handleCloseMessageDialog} data-sentry-element="MessageDialog" data-sentry-source-file="LoginBox.tsx" />
        </Fragment>;
};