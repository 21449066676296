import * as RadixCheckbox from '@radix-ui/react-checkbox';
import CheckIcon from 'share-svgs/svgs/checkmark.svg';
import { styled } from '../../theme/stitches.config';
const StyledCheckboxRoot = styled(RadixCheckbox.Root, {
  width: '20rem',
  height: '20rem',
  borderRadius: '4rem',
  border: '1rem solid $kashmir',
  background: '$dark_lighter',
  display: 'flex',
  flexShrink: 0,
  alignItems: 'center',
  justifyContent: 'center',
  transition: '.3s',
  '&[data-state="checked"]': {
    border: '1rem solid $dark_pastel_blue',
    background: '$navy'
  },
  '&:hover': {
    opacity: 0.8
  },
  '&:focus': {
    boxShadow: '0 0 0 2rem $blue'
  }
});
type Props = {
  disabled?: boolean;
  required?: boolean;
  checked?: boolean;
  defaultChecked?: boolean;
  name?: string;
  onCheckedChange(checked: boolean): void;
};
export const Checkbox = ({
  name,
  checked,
  required,
  disabled,
  defaultChecked,
  onCheckedChange
}: Props) => {
  return <CheckboxRoot name={name} checked={checked} required={required} disabled={disabled} defaultChecked={defaultChecked} onCheckedChange={onCheckedChange} data-sentry-element="CheckboxRoot" data-sentry-component="Checkbox" data-sentry-source-file="Checkbox.tsx">
            <StyledCheckboxIndicator data-sentry-element="StyledCheckboxIndicator" data-sentry-source-file="Checkbox.tsx">
                <CheckIcon data-sentry-element="CheckIcon" data-sentry-source-file="Checkbox.tsx" />
            </StyledCheckboxIndicator>
        </CheckboxRoot>;
};
const StyledCheckboxIndicator = styled(RadixCheckbox.Indicator, {
  '& svg': {
    display: 'flex',
    height: '20rem',
    path: {
      fill: '$white'
    }
  }
});
export const CheckboxRoot = StyledCheckboxRoot;