import { Fragment, ReactNode, useState } from 'react';
import { Button, Dialog, EllipsisLoader, Flex, RadixDialogContent } from 'ui';
import { LoaderIcon } from '../Svgs';
type Props = {
  messageDialog?: MessageDialogType;
  handleCloseDialog(): void;
};
export type MessageDialogType = {
  title: string;
  description?: string;
  loadingMode?: boolean;
  btnText?: string;
  loadingBtnText?: string;
  btnColor?: 'outline' | 'primary' | 'secondary' | 'bare' | 'delete' | 'loading';
  cancelBtn?: boolean;
  altDescription?: ReactNode;
  onClick?(): void;
} | undefined;
export const MessageDialog = ({
  messageDialog,
  handleCloseDialog
}: Props) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const handleBtnAction = async () => {
    try {
      setIsSubmitting(true);
      if (messageDialog && messageDialog.onClick) {
        await messageDialog.onClick();
      }
      handleCloseDialog();
      setIsSubmitting(false);
    } catch (e) {
      setIsSubmitting(false);
      alert(e);
    }
  };
  if (!messageDialog) return null;
  if (messageDialog.loadingMode) {
    return <Dialog open={true}>
                <RadixDialogContent title={messageDialog.title} centerHeading>
                    <Flex css={{
          mt: '$5'
        }}>
                        <EllipsisLoader />
                    </Flex>
                </RadixDialogContent>
            </Dialog>;
  }
  return <Dialog open={true} data-sentry-element="Dialog" data-sentry-component="MessageDialog" data-sentry-source-file="MessageDialog.tsx">
            <RadixDialogContent title={messageDialog.title} description={messageDialog.description} handleCloseDialog={handleCloseDialog} data-sentry-element="RadixDialogContent" data-sentry-source-file="MessageDialog.tsx">
                {messageDialog.altDescription && messageDialog.altDescription}
                <Flex css={{
        mt: '$5'
      }} data-sentry-element="Flex" data-sentry-source-file="MessageDialog.tsx">
                    {messageDialog.cancelBtn && <Button color="secondary" size="lg" css={{
          mr: '10rem'
        }} onClick={handleCloseDialog}>
                            cancel
                        </Button>}
                    {isSubmitting ? <Button color={'loading'} rotateSvg={isSubmitting} disabled={isSubmitting} size="lg">
                            <Fragment>
                                <LoaderIcon />{' '}
                                {messageDialog.loadingBtnText ? messageDialog.loadingBtnText : 'loading'}
                                ...
                            </Fragment>
                        </Button> : <Button color={messageDialog.btnColor ? messageDialog.btnColor : 'primary'} size="lg" onClick={handleBtnAction}>
                            {messageDialog.btnText ? messageDialog.btnText : 'got it'}
                        </Button>}
                </Flex>
            </RadixDialogContent>
        </Dialog>;
};