import { ElementRef, forwardRef } from 'react';
import { Text } from './Text';
import { VariantProps, CSS } from '../../theme/stitches.config';
import { merge } from 'lodash';
const DEFAULT_TAG = 'span';
type StatusTextVariants = VariantProps<typeof Text>;
type StatusTextProps = React.ComponentProps<typeof DEFAULT_TAG> & StatusTextVariants & {
  css?: CSS;
  as?: any;
};
const defaultCss: CSS = {
  fontSize: '$1',
  color: 'rgba(187, 187, 187, 0.65)',
  fontWeight: '$bold',
  letterSpacing: '$1',
  textTransform: 'uppercase'
};
export const StatusText = forwardRef<ElementRef<typeof DEFAULT_TAG>, StatusTextProps>((props, forwardRef) => {
  const {
    ...textProps
  } = props;
  return <Text {...textProps} ref={forwardRef} as={DEFAULT_TAG} css={{
    ...merge(defaultCss, props.css)
  }} />;
});
StatusText.displayName = 'StatusText';