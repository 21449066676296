import { motion } from 'framer-motion';
import { isEmpty } from 'lodash';
import { Fragment } from 'react';
import { useMediaQuery } from 'react-responsive';
import SessionAudio from 'share-svgs/svgs/audio-session.svg';
import CategorySearchIcon from 'share-svgs/svgs/category-search.svg';
import CreateFolderIcon from 'share-svgs/svgs/create-folder.svg';
import { Divider, FileButton, Grid, LinkButton, SearchAccordion, SearchAccordionContent, SearchAccordionItem, SearchAccordionTrigger, Skeletons, Text, VStack } from 'ui';
import { SearchCategory, SearchFolder, SearchSession } from 'ui/types';
type Props = {
  searchData?: {
    data: {
      folders: {
        data: SearchFolder[];
        additional: {
          has_more_files: boolean;
        };
      };
      categories: {
        data: SearchCategory[];
        additional: {
          has_more_files: boolean;
        };
      };
      files: {
        data: SearchSession[];
        additional: {
          has_more_files: boolean;
        };
      };
    };
  };
  hideFolderResult?: boolean;
  handleRedirectSession(session: SearchSession): void;
  handleRedirectCategoryFolder(category: SearchCategory): void;
  handleFolderRedirection(id: string, name?: string): void;
  handleSetTabValue(tab: string): void;
};
export const AllTabContent = ({
  searchData,
  hideFolderResult,
  handleRedirectSession,
  handleFolderRedirection,
  handleRedirectCategoryFolder,
  handleSetTabValue
}: Props) => {
  const isDesktop = useMediaQuery({
    query: '(min-width: 768px)'
  });
  return <Fragment>
            <VStack spacing="3" alignItems="flexStart" data-sentry-element="VStack" data-sentry-source-file="AllTabContent.tsx">
                <SearchAccordion type="single" defaultValue="sessions" collapsible data-sentry-element="SearchAccordion" data-sentry-source-file="AllTabContent.tsx">
                    <SearchAccordionItem value="sessions" data-sentry-element="SearchAccordionItem" data-sentry-source-file="AllTabContent.tsx">
                        <SearchAccordionTrigger title="Sessions" data-sentry-element="SearchAccordionTrigger" data-sentry-source-file="AllTabContent.tsx" />

                        <SearchAccordionContent data-sentry-element="SearchAccordionContent" data-sentry-source-file="AllTabContent.tsx">
                            {!searchData ? <Grid as={motion.div} initial={{
              opacity: 0
            }} animate={{
              opacity: 1
            }} exit={{
              opacity: 0
            }} columns={{
              '@initial': 1,
              '@md': 4
            }} gap="3">
                                    <Skeletons amount={4} height="70rem" />
                                </Grid> : null}

                            {searchData && searchData.data['files'] && !isEmpty(searchData.data['files'].data) ? <Grid as={motion.div} initial={{
              opacity: 0
            }} animate={{
              opacity: 1
            }} exit={{
              opacity: 0
            }} columns={{
              '@initial': 1,
              '@md': 4
            }} gap="3">
                                    {searchData.data['files'].data.map((session: SearchSession) => session.type === 'video' ? <FileButton key={session.id} imgSrc={session.thumbnails ? session.thumbnails['jpg-desktop'] : '/audio-wave.webp'} alt={session.display_name} title={session.display_name} description={isDesktop ? session.snippet : session.category.name} onClick={() => handleRedirectSession(session)} /> : <FileButton key={session.id} svg={<SessionAudio />} title={session.display_name} description={isDesktop ? session.snippet : session.category.name} onClick={() => handleRedirectSession(session)} />)}
                                </Grid> : null}

                            {searchData && searchData.data['files'] && searchData.data['files'].additional.has_more_files ? <Text as={LinkButton} textTransform="upper" onClick={() => handleSetTabValue('files')} css={{
              mt: '10rem'
            }}>
                                    view more results
                                </Text> : null}

                            {searchData && searchData.data['files'] && isEmpty(searchData.data['files'].data) ? <Text weight="light">
                                    There&apos;re no results for Sessions
                                </Text> : null}
                        </SearchAccordionContent>
                    </SearchAccordionItem>
                </SearchAccordion>
            </VStack>

            <Divider spacing={{
      '@initial': 'sm',
      '@md': 'lg'
    }} data-sentry-element="Divider" data-sentry-source-file="AllTabContent.tsx" />

            <VStack spacing="3" alignItems="flexStart" data-sentry-element="VStack" data-sentry-source-file="AllTabContent.tsx">
                <SearchAccordion type="single" defaultValue="categories" collapsible data-sentry-element="SearchAccordion" data-sentry-source-file="AllTabContent.tsx">
                    <SearchAccordionItem value="categories" data-sentry-element="SearchAccordionItem" data-sentry-source-file="AllTabContent.tsx">
                        <SearchAccordionTrigger title="Categories" data-sentry-element="SearchAccordionTrigger" data-sentry-source-file="AllTabContent.tsx" />

                        <SearchAccordionContent data-sentry-element="SearchAccordionContent" data-sentry-source-file="AllTabContent.tsx">
                            {!searchData ? <Grid as={motion.div} initial={{
              opacity: 0
            }} animate={{
              opacity: 1
            }} exit={{
              opacity: 0
            }} columns={{
              '@initial': 1,
              '@md': 4
            }} gap="3">
                                    <Skeletons amount={4} height="70rem" />
                                </Grid> : null}

                            {searchData && searchData.data['categories'] && !isEmpty(searchData.data['categories'].data) ? <Grid as={motion.div} initial={{
              opacity: 0
            }} animate={{
              opacity: 1
            }} exit={{
              opacity: 0
            }} columns={{
              '@initial': 1,
              '@md': 4
            }} gap="3">
                                    {searchData.data['categories'].data.map((category: SearchCategory) => <FileButton key={category.id} svg={<CategorySearchIcon />} title={category.name} description={isDesktop ? category.description : undefined} onClick={() => handleRedirectCategoryFolder(category)} />)}
                                </Grid> : null}

                            {searchData && searchData.data['categories'] && searchData.data['categories'].additional.has_more_files ? <Text as={LinkButton} textTransform="upper" onClick={() => handleSetTabValue('categories')} css={{
              mt: '10rem'
            }}>
                                    view more results
                                </Text> : null}

                            {searchData && searchData.data['categories'] && isEmpty(searchData.data['categories'].data) ? <Text weight="light">
                                    There&apos;re no results for Categories
                                </Text> : null}
                        </SearchAccordionContent>
                    </SearchAccordionItem>
                </SearchAccordion>
            </VStack>

            <Divider spacing={{
      '@initial': 'sm',
      '@md': 'lg'
    }} data-sentry-element="Divider" data-sentry-source-file="AllTabContent.tsx" />

            {!hideFolderResult ? <VStack spacing="3" alignItems="flexStart">
                    <SearchAccordion type="single" defaultValue="folders" collapsible>
                        <SearchAccordionItem value="folders">
                            <SearchAccordionTrigger title="Folders" />

                            <SearchAccordionContent>
                                {!searchData ? <Grid as={motion.div} initial={{
              opacity: 0
            }} animate={{
              opacity: 1
            }} exit={{
              opacity: 0
            }} columns={{
              '@initial': 1,
              '@md': 4
            }} gap="3">
                                        <Skeletons amount={4} height="70rem" />
                                    </Grid> : null}

                                {searchData && searchData.data['folders'] && !isEmpty(searchData.data['folders'].data) ? <Grid as={motion.div} initial={{
              opacity: 0
            }} animate={{
              opacity: 1
            }} exit={{
              opacity: 0
            }} columns={{
              '@initial': 1,
              '@md': 4
            }} gap="3">
                                        {searchData.data['folders'].data.map((folder: SearchFolder) => <FileButton key={folder.id} css={{
                $$svgWidth: '50rem'
              }} svg={<CreateFolderIcon />} title={folder.name} onClick={() => handleFolderRedirection(folder.id, folder.name)} />)}
                                    </Grid> : null}

                                {searchData && searchData.data['folders'] && searchData.data['folders'].additional.has_more_files ? <Text as={LinkButton} textTransform="upper" onClick={() => handleSetTabValue('folders')} css={{
              mt: '10rem'
            }}>
                                        view more results
                                    </Text> : null}

                                {searchData && searchData.data['folders'] && isEmpty(searchData.data['folders'].data) ? <Text weight="light">
                                        There&apos;re no results for Folders
                                    </Text> : null}
                            </SearchAccordionContent>
                        </SearchAccordionItem>
                    </SearchAccordion>
                </VStack> : null}
        </Fragment>;
};