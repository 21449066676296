import { styled } from '../../theme/stitches.config';
export const HStack = styled('div', {
  $$customSpacing: '20rem',
  width: '100%',
  display: 'flex',
  variants: {
    widthAuto: {
      true: {
        width: 'auto'
      }
    },
    bg: {
      cloud_night: {
        background: '$cloudy_night'
      },
      dark_lighter: {
        background: '$dark_lighter'
      },
      dark: {
        background: '$dark'
      },
      cove: {
        background: '$cove'
      },
      gray: {
        background: '$gray'
      },
      light_gray: {
        background: '$light_gray'
      },
      black_pearl: {
        background: '$black_pearl'
      },
      squid: {
        background: '$squid'
      }
    },
    bRadius: {
      '1': {
        borderRadius: '$1'
      },
      '2': {
        borderRadius: '$2'
      },
      '3': {
        borderRadius: '$3'
      }
    },
    alignItems: {
      stretch: {
        alignItems: 'stretch'
      },
      center: {
        alignItems: 'center'
      },
      flexStart: {
        alignItems: 'flex-start'
      },
      flexEnd: {
        alignItems: 'flex-end'
      }
    },
    flexBasis: {
      auto: {
        flexBasis: 'auto'
      },
      maxContent: {
        flexBasis: 'max-content'
      },
      minContent: {
        flexBasis: 'min-content'
      },
      content: {
        flexBasis: 'content'
      }
    },
    justifyContent: {
      center: {
        justifyContent: 'center'
      },
      spaceBetween: {
        justifyContent: 'space-between'
      },
      evenly: {
        justifyContent: 'space-evenly'
      },
      around: {
        justifyContent: 'space-around'
      },
      flexStart: {
        justifyContent: 'flex-start'
      },
      flexEnd: {
        justifyContent: 'flex-end'
      }
    },
    spacing: {
      '1': {
        '& > :not(:first-child)': {
          ml: '$1'
        }
      },
      '2': {
        '& > :not(:first-child)': {
          ml: '$2'
        }
      },
      '3': {
        '& > :not(:first-child)': {
          ml: '$3'
        }
      },
      '4': {
        '& > :not(:first-child)': {
          ml: '$4'
        }
      },
      '5': {
        '& > :not(:first-child)': {
          ml: '$5'
        }
      },
      '6': {
        '& > :not(:first-child)': {
          ml: '$6'
        }
      },
      '7': {
        '& > :not(:first-child)': {
          ml: '$7'
        }
      },
      '8': {
        '& > :not(:first-child)': {
          ml: '$8'
        }
      },
      '9': {
        '& > :not(:first-child)': {
          ml: '$9'
        }
      },
      custom: {
        '& > :not(:first-child)': {
          ml: '$$customSpacing'
        }
      }
    },
    p: {
      1: {
        p: '$1'
      },
      2: {
        p: '$2'
      },
      3: {
        p: '$3'
      },
      4: {
        p: '$4'
      },
      5: {
        p: '$5'
      },
      6: {
        p: '$6'
      },
      7: {
        p: '$7'
      },
      8: {
        p: '$8'
      },
      9: {
        p: '$9'
      }
    },
    overFlowXAuto: {
      true: {
        maxWidth: '100%',
        overflowX: 'auto'
      }
    }
  },
  defaultVariants: {
    spacing: '3',
    alignItems: 'center'
  }
});