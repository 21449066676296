import { getCookie, setCookie } from 'cookies-next'
import { isEmpty } from 'lodash'
import { useRouter } from 'next/router'
import { useEffect } from 'react'
import useSWR from 'swr'
import { BrandDetails } from 'ui/types'
import fetcher from 'ui/utils/fetcher'

import { create } from 'zustand'
import { persist } from 'zustand/middleware'

interface ActiveBrandIdState {
    activeBrandId: string | undefined
    setActiveBrandId: (id: string | undefined) => void
}

export const useActiveBrandId = create<ActiveBrandIdState>()(
    persist(
        (set) => ({
            activeBrandId: undefined,
            setActiveBrandId: (id: string | undefined) =>
                set({ activeBrandId: id })
        }),
        {
            name: 'vault_active_brand_id'
        }
    )
)

interface User {
    id: string
    first_name: string
    last_name: string
    email: string
    email_verified_at: string
    brands: BrandDetails[]
    roles: {
        id: number
        name: string
    }[]
    created_at: string
    subscription_notification: boolean
    has_created_highlights: boolean
    two_factor_auth_enabled: boolean
}

export const csrf = () => fetcher.get('/csrf-cookie')

export const useAuth = (): {
    user: { data: User }
    isAuthenticated?: boolean
    login(
        body: {
            email: string
            password: string
            two_factor_code?: string
            recovery_code?: string
        },
        brandId?: string
    ): void
    logout(): void
    mutate(): void
} => {
    const router = useRouter()
    const isAuthenticated = getCookie('isAuthenticated')

    const { activeBrandId, setActiveBrandId } = useActiveBrandId()

    const fetchUserParam =
        process.env.APP_NAME === 'statemints' ? 'subscribed_brands' : 'brands'

    const {
        data: user,
        error,
        mutate
    } = useSWR(
        isAuthenticated === 'true'
            ? `/auth-user${
                  fetchUserParam ? `?include[]=${fetchUserParam}` : ''
              }`
            : null,
        fetcher.get
    )

    const login = async (
        body: {
            email: string
            password: string
            two_factor_code?: string
            recovery_code?: string
        },
        brandId?: string
    ) => {
        await csrf()
        await fetcher.post(
            `/login${brandId ? `?brand_id=${brandId}` : ''}`,
            body
        )

        setCookie('isAuthenticated', 'true')

        mutate()
    }

    const logout = async () => {
        if (!error) {
            await fetcher.post('/logout').then(() => mutate())

            await setCookie('isAuthenticated', 'false')

            await setActiveBrandId(undefined)

            router.replace('/login')
        }
    }

    useEffect(() => {
        if (
            !activeBrandId &&
            isAuthenticated &&
            user &&
            !isEmpty(user.data.brands)
        ) {
            setActiveBrandId(user.data.brands[0].id)
        }

        // additional check if user has this brand, if not setActiveBrand to first brand
        if (
            user &&
            activeBrandId &&
            user.data.brands?.findIndex(
                (brand: BrandDetails) => brand.id === activeBrandId
            ) < 0
        ) {
            setActiveBrandId(user.data.brands[0].id)
        }
    }, [user, isAuthenticated])

    return {
        user,
        isAuthenticated: isAuthenticated === 'true',
        login,
        mutate,
        logout
    }
}
