import * as RadixTooltip from '@radix-ui/react-tooltip';
import { CSS, keyframes } from '@stitches/react';
import { ReactNode } from 'react';
import { styled } from '../../theme/stitches.config';
const slideUpAndFade = keyframes({
  '0%': {
    opacity: 0,
    transform: 'translateY(2px)'
  },
  '100%': {
    opacity: 1,
    transform: 'translateY(0)'
  }
});
const slideRightAndFade = keyframes({
  '0%': {
    opacity: 0,
    transform: 'translateX(-2px)'
  },
  '100%': {
    opacity: 1,
    transform: 'translateX(0)'
  }
});
const slideDownAndFade = keyframes({
  '0%': {
    opacity: 0,
    transform: 'translateY(-2px)'
  },
  '100%': {
    opacity: 1,
    transform: 'translateY(0)'
  }
});
const slideLeftAndFade = keyframes({
  '0%': {
    opacity: 0,
    transform: 'translateX(2px)'
  },
  '100%': {
    opacity: 1,
    transform: 'translateX(0)'
  }
});
export const TooltipProvider = RadixTooltip.Provider;
export const TooltipRoot = RadixTooltip.Root;
export const TooltipTrigger = RadixTooltip.Trigger;
export const TooltipPortal = RadixTooltip.Portal;
export const TooltipArrow = styled(RadixTooltip.Arrow, {
  fill: '$dark'
});
export const Tooltip = ({
  children,
  text,
  side,
  align,
  contentComponent,
  contentStyle,
  arrowStyle
}: {
  children: ReactNode;
  contentComponent?: ReactNode;
  text?: string;
  side?: 'top' | 'bottom' | 'left' | 'right';
  align?: 'center' | 'end' | 'start';
  contentStyle?: CSS;
  arrowStyle?: CSS;
}) => <TooltipProvider data-sentry-element="TooltipProvider" data-sentry-component="Tooltip" data-sentry-source-file="Tooltip.tsx">
        <TooltipRoot data-sentry-element="TooltipRoot" data-sentry-source-file="Tooltip.tsx">
            <TooltipTrigger asChild data-sentry-element="TooltipTrigger" data-sentry-source-file="Tooltip.tsx">{children}</TooltipTrigger>
            <TooltipPortal data-sentry-element="TooltipPortal" data-sentry-source-file="Tooltip.tsx">
                <TooltipContent side={side} sideOffset={8} align={align} css={contentStyle} data-sentry-element="TooltipContent" data-sentry-source-file="Tooltip.tsx">
                    {contentComponent ? contentComponent : text}
                    <TooltipArrow css={arrowStyle} data-sentry-element="TooltipArrow" data-sentry-source-file="Tooltip.tsx" />
                </TooltipContent>
            </TooltipPortal>
        </TooltipRoot>
    </TooltipProvider>;
export const TooltipContent = styled(RadixTooltip.Content, {
  borderRadius: '$1',
  p: '10rem 15rem',
  fontSize: '$1',
  lineHeight: 1,
  color: '$white',
  backgroundColor: '$dark',
  boxShadow: 'hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px',
  userSelect: 'none',
  animationDuration: '400ms',
  animationTimingFunction: 'cubic-bezier(0.16, 1, 0.3, 1)',
  willChange: 'transform, opacity',
  '&[data-state="delayed-open"]': {
    '&[data-side="top"]': {
      animationName: slideDownAndFade
    },
    '&[data-side="right"]': {
      animationName: slideLeftAndFade
    },
    '&[data-side="bottom"]': {
      animationName: slideUpAndFade
    },
    '&[data-side="left"]': {
      animationName: slideRightAndFade
    }
  }
});