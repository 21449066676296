import LeftArrow from 'share-svgs/svgs/arrow-left.svg';
import RightArrow from 'share-svgs/svgs/arrow-right.svg';
import { HStack, IconButton, Text } from 'ui';
export const AlternatePagination = ({
  total,
  currentPage,
  lastPage,
  from,
  to,
  handleNextPage,
  handlePrevPage
}: {
  total: number;
  currentPage: number;
  lastPage: number;
  from: number;
  to: number;
  handleNextPage(): void;
  handlePrevPage(): void;
}) => {
  return <HStack alignItems={'center'} widthAuto data-sentry-element="HStack" data-sentry-component="AlternatePagination" data-sentry-source-file="AlternatePagination.tsx">
            <IconButton css={{
      $$iconHeight: '10rem',
      '&[disabled] svg path': {
        stroke: '$gray'
      }
    }} disabled={currentPage === 1} onClick={handlePrevPage} data-sentry-element="IconButton" data-sentry-source-file="AlternatePagination.tsx">
                <LeftArrow data-sentry-element="LeftArrow" data-sentry-source-file="AlternatePagination.tsx" />
            </IconButton>

            <HStack spacing={'1'} data-sentry-element="HStack" data-sentry-source-file="AlternatePagination.tsx">
                <Text size={'2'} weight={'bold'} data-sentry-element="Text" data-sentry-source-file="AlternatePagination.tsx">
                    {from} - {to}
                </Text>
                <Text size={'2'} data-sentry-element="Text" data-sentry-source-file="AlternatePagination.tsx">of {total}</Text>
            </HStack>

            <IconButton css={{
      $$iconHeight: '10rem',
      '&[disabled] svg path': {
        stroke: '$gray'
      }
    }} disabled={currentPage === lastPage} onClick={handleNextPage} data-sentry-element="IconButton" data-sentry-source-file="AlternatePagination.tsx">
                <RightArrow data-sentry-element="RightArrow" data-sentry-source-file="AlternatePagination.tsx" />
            </IconButton>
        </HStack>;
};