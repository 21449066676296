import { styled } from '../../theme/stitches.config';
export const Divider = styled('hr', {
  $$borderSize: '1rem',
  $$borderColor: '$colors$squid',
  border: '$$borderSize solid $$borderColor',
  display: 'block',
  width: '100%',
  variants: {
    spacing: {
      sm: {
        m: '$2 0'
      },
      md: {
        m: '$4 0'
      },
      lg: {
        m: '$6 0'
      },
      xl: {
        m: '$8 0'
      }
    },
    widthAuto: {
      true: {
        width: 'auto'
      }
    }
  }
});