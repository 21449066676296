import { styled } from '../../theme/stitches.config';
export const TextLink = styled('a', {
  fontSize: '$2',
  color: '$sky',
  fontWeight: '$bold',
  letterSpacing: '$1',
  textTransform: 'uppercase',
  textDecoration: 'none',
  cursor: 'pointer',
  display: 'inline',
  '&:focus': {
    outlineWidth: '1rem',
    outlineStyle: 'dashed',
    outlineOffset: '1rem',
    textDecorationLine: 'none'
  }
});