import { merge } from 'lodash';
import { ElementRef, forwardRef } from 'react';
import { CSS, VariantProps } from '../../theme/stitches.config';
import { Text } from '../Text';
const DEFAULT_TAG = 'h1';
type TextSizeVariants = Pick<VariantProps<typeof Text>, 'size'>;
type HeadingSizeVariants = '1' | '2' | '3' | '4';
type HeadingVariants = {
  size?: HeadingSizeVariants;
} & Omit<VariantProps<typeof Text>, 'size'>;
type HeadingProps = React.ComponentProps<typeof DEFAULT_TAG> & HeadingVariants & {
  css?: CSS;
  as?: any;
};
export const Heading = forwardRef<ElementRef<typeof DEFAULT_TAG>, HeadingProps>((props, forwardedRef) => {
  // '2' here is the default heading size variant
  const {
    size = '1',
    textTransform,
    textAlign,
    ...textProps
  } = props;
  // This is the mapping of Heading Variants to Text variants
  const textSize: Record<HeadingSizeVariants, TextSizeVariants['size']> = {
    1: {
      '@initial': '5'
    },
    2: {
      '@initial': '4'
    },
    3: {
      '@initial': '3'
    },
    4: {
      '@initial': '2'
    }
  };

  // This is the mapping of Heading Variants to Text css
  const textCss: Record<HeadingSizeVariants, CSS> = {
    1: {
      fontWeight: '$bold',
      lineHeight: '22rem'
    },
    2: {
      fontWeight: '$bold',
      lineHeight: '20rem'
    },
    3: {
      fontWeight: '$bold',
      lineHeight: '18rem'
    },
    4: {
      fontWeight: '$bold',
      lineHeight: '16rem'
    }
  };
  return <Text as={DEFAULT_TAG} {...textProps} ref={forwardedRef} size={textSize[size]} textAlign={textAlign} textTransform={textTransform} css={{
    fontVariantNumeric: 'proportional-nums',
    ...merge({
      ...textCss[size]
    }, props.css)
  }} />;
});
Heading.displayName = 'Heading';