import { MonologueElement, MonologueInterface } from '../types'

export const getMonologueText = (monologue: MonologueInterface) => {
    let text = ''
    text = monologue.elements
        .map((element: MonologueElement) => element.value)
        .join('')

    return text ? text : ''
}
